
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component({
  components: {
    StockDocumentDetails: () => import('@/components/stock/StockDocumentDetails.vue'),
  },
})
  export default class StockDocumentDetailsCell extends Cell {

  }
